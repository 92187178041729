export const SHOPIFY_INVOICE_EMAIL_TEMPLATE =
`
<!DOCTYPE html>
<html lang="en">
<head>
  <title>{{ email_title }}</title>
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
  <meta name="viewport" content="width=device-width">
  <link rel="stylesheet" type="text/css" href="/assets/notifications/styles.css">
  <style>
    .button__cell { background: {{ shop.email_accent_color }}; }
    a, a:hover, a:active, a:visited { color: {{ shop.email_accent_color }}; }
  </style>
</head>

<body>
<table class="body">
  <tr>
    <td>
      <table class="header row">
  <tr>
    <td class="header__cell">
      <center>

        <table class="container">
          <tr>
            <td>

              <table class="row">
                <tr>
                  <td class="shop-name__cell">
                    {%- if shop.email_logo_url %}
                      <img src="{{shop.email_logo_url}}" alt="{{ shop.name }}" width="{{ shop.email_logo_width }}">
                    {%- else %}
                      <h1 class="shop-name__text">
                        <a href="{{shop.url}}">{{ shop.name }}</a>
                      </h1>
                    {%- endif %}
                  </td>

                    <td class="order-number__cell">
                      <span class="order-number__text">
                        Invoice {{ order_name }}
                      </span>
                    </td>
                </tr>
              </table>

            </td>
          </tr>
        </table>

      </center>
    </td>
  </tr>
</table>

      <table class="row content">
  <tr>
    <td class="content__cell">
      <center>
        <table class="container">
          <tr>
            <td>

        {% assign due_date = payment_terms.next_payment.due_at | default: nil %}

        {% if payment_terms.type == 'receipt' and due_date == nil %}
            {% assign due_date = 'now' %}
        {% endif %}

        {% if payment_terms.type == 'fulfillment' and payment_terms.next_payment.due_at == nil %}
          <h2> Payment of {{ order.total_outstanding | money }} is due on fulfillment </h2>
        {% else %}
          <h2>Payment of {{ order.total_outstanding | money }} is due {{ due_date | date: format: 'date' }}</h2>
        {% endif %}

        {% if custom_message != blank %}
        <p>{{ custom_message }}</p>
        {% endif %}
        {% if attributes.streamlined_invoice_url or checkout_payment_collection_url %}
        <table class="row actions">
  <tr>
    <td class="actions__cell">
      <table class="button main-action-cell">
        <tr>
          <td class="button__cell"><a href="{{ attributes.streamlined_invoice_url | default: checkout_payment_collection_url }}" class="button__text">Pay now</a></td>
        </tr>
      </table>
    </td>
  </tr>
</table>

        {% endif %}

            </td>
          </tr>
        </table>
      </center>
    </td>
  </tr>
</table>

      <table class="row section">
  <tr>
    <td class="section__cell">
      <center>
        <table class="container">
          <tr>
            <td>
              <h3>Order summary</h3>
            </td>
          </tr>
        </table>
        <table class="container">
          <tr>
            <td>


<table class="row">
  {% for line in subtotal_line_items %}
  <tr class="order-list__item">
    <td class="order-list__item__cell">
      <table>
        <td>
          {% if line.image %}
            <img src="{{ line | img_url: 'compact_cropped' }}" align="left" width="60" height="60" class="order-list__product-image"/>
          {% endif %}
        </td>
        <td class="order-list__product-description-cell">
          {% if line.product.title %}
            {% assign line_title = line.product.title %}
          {% else %}
            {% assign line_title = line.title %}
          {% endif %}

          {% if line.quantity < line.quantity %}
            {% capture line_display %} {{ line.quantity }} of {{ line.quantity }} {% endcapture %}
          {% else %}
            {% assign line_display = line.quantity  %}
          {% endif %}

          <span class="order-list__item-title">{{ line_title }}&nbsp;&times;&nbsp;{{ line_display }}</span><br/>

          {% if line.variant.title != 'Default Title' %}
            <span class="order-list__item-variant">{{ line.variant.title }}</span><br/>
          {% endif %}

          {% if line.selling_plan_allocation %}
            <span class="order-list__item-variant">{{ line.selling_plan_allocation.selling_plan.name }}</span><br/>
          {% endif %}

          {% if line.refunded_quantity > 0 %}
            <span class="order-list__item-refunded">Refunded</span>
          {% endif %}

          {% if line.discount_allocations %}
            {% for discount_allocation in line.discount_allocations %}
              {% if discount_allocation.discount_application.target_selection != 'all' %}
                <span class="order-list__item-discount-allocation">
                  <img src="{{ 'notifications/discounttag.png' | shopify_asset_url }}" width="18" height="18" class="discount-tag-icon" />
                  <span>
                    {{ discount_allocation.discount_application.title | upcase }}
                    (-{{ discount_allocation.amount | money }})
                  </span>
                </span>
              {% endif %}
            {% endfor %}
          {% endif %}
        </td>
          <td class="order-list__price-cell">
            {% if line.original_line_price != line.final_line_price %}
              <del class="order-list__item-original-price">{{ line.original_line_price | money }}</del>
            {% endif %}
            <p class="order-list__item-price">
              {% if line.final_line_price > 0 %}
                {{ line.final_line_price | money }}
              {% else %}
                Free
              {% endif %}
            </p>
          </td>
      </table>
    </td>
  </tr>{% endfor %}
</table>

        <table class="row subtotal-lines">
  <tr>
    <td class="subtotal-spacer"></td>
    <td>
      <table class="row subtotal-table">
        {% for discount_application in discount_applications %}
        {% if discount_application.target_selection == 'all' %}
        {% capture discount_title %}
        {% if discount_application.title %}
        {{ discount_application.title | upcase }}
        {% else %}
        Discount
        {% endif %}
        {% endcapture %}

<tr class="subtotal-line">
  <td class="subtotal-line__title">
    <p>
      <span>Discount</span>
        <span class="subtotal-line__discount">
          <img src="{{ 'notifications/discounttag.png' | shopify_asset_url }}" width="18" height="18" class="discount-tag-icon" />
          <span class="subtotal-line__discount-title">{{ discount_title }}</span>
        </span>
    </p>
  </td>
  <td class="subtotal-line__value">
    <strong>-{{ discount_application.total_allocated_amount | money }}</strong>
  </td>
</tr>

        {% endif %}
        {% endfor %}


<tr class="subtotal-line">
  <td class="subtotal-line__title">
    <p>
      <span>Subtotal</span>
    </p>
  </td>
  <td class="subtotal-line__value">
    <strong>{{ subtotal_price | money }}</strong>
  </td>
</tr>


<tr class="subtotal-line">
  <td class="subtotal-line__title">
    <p>
      <span>Shipping</span>
    </p>
  </td>
  <td class="subtotal-line__value">
    <strong>{{ shipping_price | money }}</strong>
  </td>
</tr>



<tr class="subtotal-line">
  <td class="subtotal-line__title">
    <p>
      <span>Taxes</span>
    </p>
  </td>
  <td class="subtotal-line__value">
    <strong>{{ tax_price | money }}</strong>
  </td>
</tr>


        {% if total_tip_received and total_tip_received > 0 %}

<tr class="subtotal-line">
  <td class="subtotal-line__title">
    <p>
      <span>Tip</span>
    </p>
  </td>
  <td class="subtotal-line__value">
    <strong>{{ total_tip_received | money }}</strong>
  </td>
</tr>

        {% endif %}
      </table>
    </td>
  </tr>
</table>

        <table class="row subtotal-lines">
  <tr>
    {% if total_price > total_outstanding %}
    <td class="subtotal-spacer"></td>
      <td>
        <table class="row subtotal-table">

<tr class="subtotal-line">
  <td class="subtotal-line__title">
    <p>
      <span>Updated total</span>
    </p>
  </td>
  <td class="subtotal-line__value">
    <strong>{{ total_price | money }}</strong>
  </td>
</tr>


<tr class="subtotal-line">
  <td class="subtotal-line__title">
    <p>
      <span>Already paid</span>
    </p>
  </td>
  <td class="subtotal-line__value">
    <strong>{{ net_payment | money }}</strong>
  </td>
</tr>

        </table>
        <table class="row subtotal-table subtotal-table--total">

<tr class="subtotal-line">
  <td class="subtotal-line__title">
    <p>
      <span>Amount to pay</span>
    </p>
  </td>
  <td class="subtotal-line__value">
    <strong>{{ total_outstanding | money_with_currency }}</strong>
  </td>
</tr>

        </table>
      </td>
    {% else %}
      <table class="row subtotal-table subtotal-table--total">

<tr class="subtotal-line">
  <td class="subtotal-line__title">
    <p>
      <span>Amount to pay</span>
    </p>
  </td>
  <td class="subtotal-line__value">
    <strong>{{ total_outstanding | money_with_currency }}</strong>
  </td>
</tr>

      </table>
    {% endif %}
  </tr>
</table>


            </td>
          </tr>
        </table>
      </center>
    </td>
  </tr>
</table>

      {% if shipping_address or billing_address or shipping_method %}
      <table class="row section">
  <tr>
    <td class="section__cell">
      <center>
        <table class="container">
          <tr>
            <td>
              <h3>Customer information</h3>
            </td>
          </tr>
        </table>
        <table class="container">
          <tr>
            <td>

        <table class="row">
          <tr>
            {% if shipping_address %}
            <td class="customer-info__item">
              <h4>Shipping address</h4>
              {{ shipping_address | format_address }}
            </td>
            {% endif %}

            {% if billing_address %}
            <td class="customer-info__item">
              <h4>Billing address</h4>
              {{ billing_address | format_address }}
            </td>
            {% endif %}
          </tr>
        </table>
        {% if shipping_method %}
        <table class="row">
          <tr>
            <td class="customer-info__item customer-info__item--last">
              <h4>Shipping method</h4>
              <p>{{ shipping_method.title }}<br/>{{ shipping_method.price | money }}</p>
            </td>
          </tr>
        </table>
        {% endif %}

            </td>
          </tr>
        </table>
      </center>
    </td>
  </tr>
</table>
      {% endif %}

      <table class="row footer">
  <tr>
    <td class="footer__cell">
      <center>
        <table class="container">
          <tr>
            <td>

              <p class="disclaimer__subtext">If you have any questions, reply to this email or contact us at <a href="mailto:{{ shop.email }}">{{ shop.email }}</a></p>
            </td>
          </tr>
        </table>
      </center>
    </td>
  </tr>
</table>

<img src="{{ 'notifications/spacer.png' | shopify_asset_url }}" class="spacer" height="1" />

    </td>
  </tr>
</table>
</body>
</html>
`